<template>
    <div class="card-producto d-middle m-2 p-1" :class="{'border-general':producto.promocion && producto.data_promocion.borde}">
        <div class="position-relative" @click.stop="$emit('accion')">
            <img class="br-10 obj-cover" height="90" width="90" :src="producto.imagen" alt="" />
            <p v-show="tipoImpuesto === 2 && producto.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center">IVA incluido</p>
            <p v-show="tipoImpuesto === 1 && producto.porcentaje_impuesto > 0" class="bg-general2 br-10 text-white f-10 text-center"> + IVA</p>
        </div>
        <div class="col px-2 text-general">
            <p class="nombre2 lh-18">{{ producto.nombre }}</p>
            <p class="text-general2 f-13">{{ producto.presentacion }}</p>
            <div class="row mx-0 align-items-center pb-1">
                <div v-if="producto.promocion" class="">
                    <span class="f-600">{{ convertMoney(funValor(producto.data_promocion.promo_valor),producto.idm_moneda) }}</span>
                    <strike class="ml-2 f-12 text-general2">
                        {{ convertMoney(funValor(producto.precio),producto.idm_moneda) }}
                    </strike>
                </div>
                <div v-else class="">
                    <span class="text-general f-600">{{ convertMoney(funValor(producto.precio),producto.idm_moneda) }}</span>
                </div>
            </div>
            <span v-if="producto.promocion" class="bg-general br-10 text-white px-2 f-14">{{ producto.data_promocion.texto }}</span>
        </div>
        <div class="col-auto d-middle">
            <div class="cr-pointer text-center ml-auto br-3 bg-general" @click.stop="agregar(producto.id)">
                <i class="icon-plus text-white" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        producto: {
            type: Object,
            default: () => {
                return {
                    nombre: '',
                    presentacion: '',
                    sigla: 'ml',
                    precio: 0,
                }
            }
        },
        tipoImpuesto:{
            type:Number,
            default:0
        }
    },
    data(){
        return {
            tipo: 'libre',
            num: 1,
            colores: [ '', 'bg-gr-general', 'bg-gr-red', 'bg-gr-purple', 'bg-gr-pink' ],
            text: [ '', 'text-gr-general', 'text-gr-red', 'text-gr-purple', 'text-gr-pink' ],
            border: [ '', 'border-blue', 'border-red', 'border-purple', 'border-pink' ],
            id_selected:null
        }
    },
    methods: {
        agregar(id){
            if(this.id_selected === id) return
            this.$emit('agregar',id)
            this.id_selected = id
        },
        funValor(value){
            if(this.tipoImpuesto === 0){
                return value
            }
            if(this.tipoImpuesto === 1){
                return value / ( 1 + this.producto.porcentaje_impuesto/100)
            }
            if(this.tipoImpuesto === 2 || this.tipoImpuesto === 3){
                return value
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.card-producto{
    width: 400px;
    border-radius: 16px;
}
.nombre2{
    height: 38px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
